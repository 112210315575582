<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">代理对账</span>
        <img src="../../assets/right.png" alt />
        <span class="center">对账详情</span>
      </div>
      <!-- <h4 class="title">对账/详情</h4> -->
      <el-button size="small" @click="drawer = true" type="primary" icon="el-icon-s-unfold">历史记录</el-button>
    </div>
    <el-drawer :visible.sync="drawer" :with-header="false">
      <div class="block">
        <el-timeline>
          <el-timeline-item
            :timestamp="item.createTime"
            placement="top"
            v-for="(item, index) in RecordList"
            :key="index"
          >
            <el-card>
              <h4>备注：{{ item.remark }}</h4>
              <p>{{ item.operator.name }} 提交于 {{ item.createTime }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
    <Divider :title="'对账基本信息'"></Divider>
    <div class="text item">
      <el-row>
        <el-col :span="12" class="text-align">公司名称：{{ enterprise.name }}</el-col>
        <el-col :span="12" class="text-align">结算金额：{{ tableList.amount }}</el-col>
        <el-col :span="12" class="text-align">服务中心：{{ tableList.pay_platform | platformVal }}</el-col>
        <el-col :span="12" class="text-align">统一社会信用代码：{{ enterprise.idCardNumber }}</el-col>
        <el-col :span="12" class="text-align">对账时间：{{ tableList.createTime }}</el-col>
        <el-col :span="12" class="text-align">账月份：{{ tableList.checkTime }}</el-col>
        <el-col :span="12" class="text-align">
          对账状态：
          <el-tag v-if="tableList.state === '1'" type="info">待复核</el-tag>
          <el-tag v-if="tableList.state === '2'" type="danger">已驳回</el-tag>
          <el-tag v-if="tableList.state === '4'" type="info">待审批</el-tag>
          <el-tag v-if="tableList.state === '5'" type="success">已审批</el-tag>
          <el-tag v-if="tableList.state === '3'" type="info">待确认</el-tag>
        </el-col>
        <el-col :span="12" class="text-align">审批人：{{ checkerInfo.name }}</el-col>
        <el-col :span="12" class="text-align">核算人：{{ handlerInfo.name }}</el-col>
        <el-col :span="12" class="text-align">
          对账表:
          <a
            style="color:#409EFF;text-decoration: none;`"
            :href="tableList.filePath"
          >{{ dzbName }}</a>
        </el-col>
        <el-col
          :span="12"
          v-if="tableList.expressageOdd"
          class="text-align"
        >快递单号：{{ tableList.expressageOdd }}</el-col>
        <el-col
          :span="12"
          v-if="tableList.expressage"
          class="text-align"
        >快递公司：{{ tableList.expressage }}</el-col>
        <el-col :span="12" v-if="tableList.invoiceImage" class="text-align">
          发票图片：
          <el-popover placement="right-start" title="图片" trigger="hover">
            <img :src="tableList.invoiceImage" alt srcset style="width: 300px" />
            <span
              slot="reference"
              trigger="hover"
              class="el-icon-postcard font-color-brand"
              style="cursor: pointer"
            >发票图片</span>
          </el-popover>
        </el-col>
        <el-col :span="12" v-if="tableList.voucherImage" class="text-align">
          对账凭证：
          <el-popover placement="right-start" title="图片" trigger="hover">
            <img :src="tableList.voucherImage" alt srcset style="width: 300px" />
            <span
              slot="reference"
              trigger="hover"
              class="el-icon-postcard font-color-brand"
              style="cursor: pointer"
            >对账凭证</span>
          </el-popover>
        </el-col>
      </el-row>
    </div>

    <div class="button" v-if="tableList.state === '3'">
      <el-button
        type="primary"
        plain
        @click="dialogVisible = true"
        v-access
        data-ctrl="agent.confirm"
      >确认无误</el-button>
    </div>
    <div class="button" v-if="tableList.state === '4'">
      <el-button type="primary" v-access data-ctrl="agent.pass" plain @click="shepiShow = true">通过审批</el-button>
    </div>
    <el-dialog title="是否通过审批" :visible.sync="shepiShow" width="30%" :before-close="handleClose">
      <el-upload
        class="upload-demo"
        :action="action"
        :on-change="handleChange"
        :file-list="fileList"
        :on-success="successFile"
        accept=".png, .jpg, jpeg, JPG, JPEG"
        :limit="1"
      >
        <el-button type="primary" size="mini" style="border-radius: 5px">上传证件凭证</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
      <img v-if="invoice" style="width:300px;height:200px" :src="invoice" alt />
      <span slot="footer" class="dialog-footer">
        <el-button @click="shepiShow = false">取 消</el-button>
        <el-button type="primary" @click="goApproval()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="确认交易方式" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-radio v-model="radio" label="1">提供发票</el-radio>
      <el-radio v-model="radio" label="2">扣实提现</el-radio>

      <div v-if="radio == '1'" style="padding: 20px">
        <div>
          <div style="padding: 10px 0">
            <el-radio v-model="InvoiceType" label="1">电子发票</el-radio>
          </div>
          <div style="padding: 10px 0">
            <el-radio v-model="InvoiceType" label="2">快递配送</el-radio>
          </div>
        </div>
        <div v-if="InvoiceType == '1'">
          <el-upload
            class="upload-demo"
            :action="action"
            :on-change="handleChange"
            :file-list="fileList"
            :on-success="successFile"
          >
            <el-button type="primary" size="mini" style="border-radius: 5px">上传照片</el-button>
          </el-upload>
        </div>
        <div v-if="InvoiceType == '2'">
          <div style="display: flex; align-items: center; padding: 10px 0">
            <span>快递公司:</span>
            <el-input
              style="width: 50%"
              size="small"
              v-model="CourierServicesCompany"
              placeholder="请输入快递公司"
            ></el-input>
          </div>
          <div style="display: flex; align-items: center; padding: 10px 0">
            <span>快递编号:</span>
            <el-input style="width: 50%" size="small" v-model="ExpressNumber" placeholder="请输入快递编号"></el-input>
          </div>
        </div>
      </div>
      <div v-else></div>
      <div style="display: flex; align-items: center; padding: 20px 0">
        <p style="padding: 10px">备注:</p>
        <el-input style="width: 50%" size="small" type="text" v-model="remark" placeholder="请输入备注"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">拒 绝</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
// import baseUrl from "../../config/config";
export const AgentDescribe = api()('agent.describe.json');
export const AgentEdit = api()('agent.edit.json');
export const AgentRecord = api()('agentRecords.list.json');
export default {
  components: {
    Divider: () => import('/src/components/divider.vue')
  },
  data () {
    return {
      code: '',
      tableList: {},
      dzbName: '',
      dialogVisible: false,
      fileList: [],
      action: '',
      enterprise: {},
      handlerInfo: {},
      checkerInfo: {},
      radio: '2',
      InvoiceType: '1',
      remark: '',
      ExpressNumber: '',

      invoice: '',
      CourierServicesCompany: '',
      drawer: false,
      RecordList: [],
      shepiShow: false
    };
  },
  created () {
    this.action = window.location.origin + '/enterprise.upload.json';
  },
  mounted () {
    this.code = this.$route.query.code;
    console.log(this.code);
    this.getInvoiceList();
    this.getAgentRecord();
  },
  methods: {
    async goAgentEdit (id) {
      var data = {};
      if (this.remark == '') {
        this.$message('请填写理由');
        return;
      } else {
        if (id == '1') {
          if (this.InvoiceType == 2) {
            if (this.CourierServicesCompany == '') {
              this.$message('请输入快递公司');
              return;
            } else if (this.ExpressNumber == '') {
              this.$message('请输入快递编号');
              return;
            } else {
              data = {
                code: this.code,
                // voucherImage: this.invoice,
                expressageOdd: this.ExpressNumber,
                payType: this.radio,
                invoiceImage: this.invoice,
                expressage: this.CourierServicesCompany,
                state: '4',
                remark: this.remark
              };
            }
          } else {
            data = {
              code: this.code,

              payType: this.radio,
              invoiceImage: this.invoice,
              state: '4',
              remark: this.remark
            };
          }
        } else {
          data = {
            code: this.code,
            remark: this.remark,
            state: '2'
          };
        }
      }
      const result = await AgentEdit(data);
      this.dialogVisible = false;
      this.fileList = [];
      (this.invoice = ''), console.log(result);
      this.getAgentRecord();
      this.getInvoiceList();
    },
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    determine () {
      this.goAgentEdit(1);
    },
    async goApproval () {
      console.log(this.fileList);
      if (!this.fileList) {
        this.$message('请上传证件凭证');
        return;
      } else {
        let data = {
          code: this.code,
          state: '5',
          voucherImage: this.invoice
        };
        const result = await AgentEdit(data);
        this.dialogVisible = false;
        this.fileList = [];
        (this.invoice = ''), (this.shepiShow = false);
        console.log(result);
        this.getAgentRecord();
        this.getInvoiceList();
      }
    },
    cancel () {
      this.goAgentEdit(2);
    },
    handleClose () {
      this.dialogVisible = false;
      this.shepiShow = false;
      this.getInvoiceList();
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.invoice = res.data;
      // alert(this.invoice);
    },
    goBack () {
      this.$router.go(-1);
    },
    async getInvoiceList () {
      const result = await AgentDescribe({
        code: this.code
      });
      // console.log(result);
      this.tableList = result || [];
      this.enterprise = this.tableList.enterprise;
      this.handlerInfo = this.tableList.handlerInfo;
      this.checkerInfo = this.tableList.checkerInfo;
      let m = this.tableList.filePath.split('/');
      this.dzbName = m[5];
      console.log(this.dzbName);
    },
    async getAgentRecord () {
      const result = await AgentRecord({
        agentCode: this.code
      });
      console.log(result.list);
      this.RecordList = result.list;
    }
  }
};
</script>
<style lang="scss">
// .title {
//   padding: 20px;
// }

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  display: flex;
  align-items: center;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.button {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .dowm {
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid #000;
    color: #000;
  }

  .determine {
    background: #169bd5;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #169bd5;
    margin: 0 40px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}

.block {
  padding: 40px;
}
</style>
